import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

const KappLogin = () => import( "../../components/KappLogin" );
const KappMain = () => import( '../../pages/KappMain/KappMain' );
const KappPostsList = () => import( '../../pages/Posts/KappPostsList' );
const KappKalender = () => import( '../../pages/KappKalender/KappKalender' );
const KappKontakte = () => import( '../../pages/Kontakte/KappKontakte' );
const KappProfile = () => import( '../../pages/KappProfile/KappProfile' );
const KappInfocenter = () => import( '../../pages/Infocenter/KappInfocenter' );
const MainSidebar = () => import( '../../components/MainSidebar' );
const InfocenterMenu = () => import( '../../pages/InfocenterMenu' );
const NewsSingle = () => import( '../../pages/NewsSingle/NewsSingle' );
const ProfilSingle = () => import( '../../pages/Kontakte/ProfilSingle' );
const InfoMap = () => import( '../../pages/Infocenter/InfoMap' );
const ContentPage = () => import( '../../pages/Infocenter/ContentPage' );
const PasswordChange = () => import( '../../pages/PasswordChange' );
const Mediathek = () => import( '../../pages/Mediathek/MediathekList.vue' );

const router = createRouter({
	history: createWebHistory(),
	scrollBehavior(){
		return {top: 0};
		// return (to.name === 'SingleNews' ? {top: 0} : {});
	},
	routes: [
		{
			path: '/',
			name: 'Main',
			components: {
				default: KappMain,
				sidebar: MainSidebar
			},
			meta: {
				title: 'Aktuelles',
				uid: 2,
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Navigation',
							page: route.path
						};
					}
				}
			}
		},
		{
			path: '/login',
			name: 'Login',
			authRequired: false,
			component: KappLogin,
			meta: {
				title: 'Login'
			}
		},
		{
			path: '/passwort',
			name: 'PasswordChange',
			authRequired: false,
			component: PasswordChange,
			meta: {
				title: 'Passwort andern'
			}
		},
		{
			path: '/beitraege',
			name: 'Posts',
			components: {
				default: KappPostsList,
				sidebar: MainSidebar
			},
			meta: {
				title: 'Beiträge',
				uid: 8,
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Navigation',
							page: route.path
						};
					}
				}
			}
		},
		{
			path: '/kalender',
			name: 'Kalender',
			component: KappKalender,
			meta: {
				uid: 6,
				title: 'Kalender',
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Navigation',
							page: route.path
						};
					}
				}
			}
		},
		{
			path: '/mediathek',
			name: 'Mediathek',
			components: {
				default: Mediathek,
				sidebar: MainSidebar
			},
			meta: {
				title: 'Mediathek',
				uid: 22,
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Navigation',
							page: route.path
						};
					}
				}
			}
		},
		{
			path: '/profile',
			name: 'Profile',
			component: KappKontakte,
			meta: {
				title: 'Profile',
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Navigation',
							page: route.path
						};
					}
				}
			}
		},
		{
			path: '/profil/:id',
			name: 'ProfilSingle',
			component: ProfilSingle,
			params: {
				id: {}
			},
			meta: {
				title: 'Profile',
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Navigation',
							page: route.path
						};
					}
				}
			}
		},
		{
			path: '/mein_profil',
			name: 'Profil',
			components: {
				default: KappProfile,
				sidebar: MainSidebar
			},
			meta: {
				title: 'Profil',
				authRequired: true
			}
		},
		{
			path: '/infocenter',
			name: 'Infocenter',
			components: {
				default: KappInfocenter,
				sidebar: InfocenterMenu
			},
			redirect: '/infocenter/alles',
			meta: {
				uid: 10,
				title: 'Infocenter',
				image: require('@/assets/images/ic_infocenter.svg'),
				authRequired: true,
				analytics: {
					pageviewTemplate( route ) {
						return {
							title: 'Infocenter',
							page: route.path
						};
					}
				}
			},
			children:[
				{
					path: 'la_standorte',
					name: 'Infocenter_Standorte',
					component: InfoMap,
					meta: {
						title: 'Standorte des Landratsamtes NEW',
						mapSrc: 'https://newgis.maps.arcgis.com/apps/webappviewer/index.html?id=01e0792f5028414ca90ce188410f35cf'
					}
				},
				{
					path: 'new_gemeinden',
					name: 'Infocenter_Gemeinden',
					component: InfoMap,
					meta: {
						title: 'Rathäuser der Gemeinden in NEW',
						mapSrc: 'https://newgis.maps.arcgis.com/apps/Embed/index.html?webmap=cab0bbc18b644fffbce4f8692b9808b8&extent=11.635,49.501,12.6492,49.8729&home=true&zoom=true&scale=true&search=true&searchextent=true&basemap_gallery=true&disable_scroll=true&theme=light'
					}
				},
				{
					path: 'wetter_verkehr',
					name: 'Infocenter_Wetter',
					component: InfoMap,
					meta: {
						title: 'Aktuelle Wetter- und Verkehrslage in NEW',
						mapSrc: 'https://newgis.maps.arcgis.com/apps/PublicInformation/index.html?appid=d34c31d7e5f341a890639398a113d0d0'
					}
				},
				{
					path: ':slug',
					component: ContentPage,
					name: 'infocenter_contentpage'
				},
			]
		},
		{
			name: 'SingleNews',
			path: '/news/:id',
			component: NewsSingle,
			meta: {
				title: 'News'
			}
		},
		{
			name: 'Beitrag',
			path: '/beitrag/:id',
			component: NewsSingle,
			meta: {
				title: 'Beitrag'
			}
		},
		{
			name: 'Media',
			path: '/media/:id',
			component: NewsSingle,
			meta: {
				title: 'Media'
			}
		}
	]

});

router.beforeEach(
	function ( to, from, next ) {

		if ( to.name === 'Login' && store.getters.isAuthenticated ) {
			next({path: '/mein_profil'});
		}

		if ( false === to.meta.authRequired ) next();

		if ( (to.meta.authRequired) && store.getters.isAuthenticated ) {
			next();
		}
		else if ( to.meta.authRequired && !store.getters.isAuthenticated ) {
			next({name: 'Login'});
		}
		else {
			next();
		}
	});

export default router;
